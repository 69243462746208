import * as React from "react";
import "../../views/Staff.scss";

import { cilPencil, cilTrash, cilUser } from "@coreui/icons";
import { IconButton } from "../Atomics";
import { Roles } from "../types";
import EditEmergency from "./EditEmergency";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveRolesUser } from "../../redux/reducers/userReducer";
import usePermission from "../../hooks/usePermission";
import { convertUTCtoLocale } from "../../utils/formatConverters";
import moment from "moment";
import EditEmergencyRelation from "./EditEmergencyRelation";
import { FaList } from "react-icons/fa";
//service

interface ParentsRowProps {
  parent: Roles;
  removeUser: (id: number) => void;
  refreshParents: () => void;
}

const FwdRowActivities: React.FC<ParentsRowProps> = ({
  parent,
  removeUser,
  refreshParents,
}) => {
  const { checkPermission } = usePermission()
  const dispatch = useDispatch();

  const [visibleEditParent, setVisibleEditParent] = React.useState(false);
  const localeDate = convertUTCtoLocale(parent.createdAt);
  function onPressAdd() {
    setVisibleEditParent(!visibleEditParent);
  }
  const history = useHistory();

  const handleNavigation = (item: any) => {
    history.push('/alert-activity-count', item);
  };

  console.log("first", parent)
  return (
    <>

      <div key={parent?.id} className="d-flex py-3 px-3 user-information-container justify-content-around">
        <div className="col d-flex justify-content-start">
          <p className="text-light word-break">
            {parent?.title ? parent?.title : '-'}
          </p>
        </div>

        <div className="col d-flex justify-content-start">
          <p className="text-light word-break">
            {parent?.total_student ? parent?.total_student : '-'}
          </p>
        </div>

        <div className="col d-flex justify-content-start">
          <p className="text-light word-break">
            {parent?.acknowledge_count ? parent?.acknowledge_count : 0}
          </p>
        </div>

        <div className="col d-flex justify-content-start">
          <p className="text-light word-break">
            {parent?.createdAt ? moment(parent?.createdAt).fromNow() : '-'}
          </p>
        </div>

        <div className="col d-flex justify-content-start">
          <p className="text-light word-break">
            {parent?.status ? parent?.status : '-'}
          </p>
        </div>

        <div className="col-auto d-flex justify-content-center">
          <div onClick={() => handleNavigation(parent?.alert_activity_count)} className="text-light word-break" style={{ width: "100%" }}>
            <FaList />
          </div>
        </div>
      </div>


      <EditEmergencyRelation
        visible={visibleEditParent}
        closeModal={onPressAdd}
        refreshParents={refreshParents}
        parentData={{
          parentId: parent.id,
          name: parent.name
        }}
      />
    </>
  );
};

export default FwdRowActivities;
