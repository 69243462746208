import React, { useEffect, useState } from 'react';
import { IconButton, Input, MenuHeader, PageContainer } from "../components/Atomics";
import { CSpinner } from "@coreui/react";
import { cilPencil } from "@coreui/icons";
import { getAlarmAlerts } from "../services/alarmAlertsService";
import HandleUIError from "../utils/HandleUIError";
import { getActivityReq } from "../services/activityLogService";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { FaList } from "react-icons/fa6";
import CIcon from "@coreui/icons-react";
import { cilPeople } from "@coreui/icons";
import { CAvatar } from "@coreui/react";


const StudentList = () => {
    const params: any = useParams()
    const [loading, setLoading] = useState(false)
    const [logs, setLogs] = useState<any>([]);

    const getActivityLogs = () => {
        getActivityReq(params?.id)
            .then((res: any) => {
                console.log('users1', res)
                setLogs(res);
            })
            .catch((e: any) => {
                HandleUIError(e);
            });
    }

    const location = useLocation();
    const dataArray = Array.isArray(location.state) ? location.state : [];

    console.log("dataArray", dataArray);

    dataArray.map((item) => {
        console.log("itemitemitemitem",item);
    });

    useEffect(() => {
        getActivityLogs()
    }, []);
    return (
        <>
            <div className="bg-main pb-5 over-flow-auto h-100">
                <PageContainer className="page-container-staff d-flex flex-column justify-content-start pb-3 mx-4">
                    <div className="row align-items-end justify-content-between mb-4">
                        <div className="col-auto">
                            <h3 className="text-white mb-0">
                                Acknowledgement
                            </h3>
                        </div>
                    </div>


                    <div className="row justify-content-end mb-3 d-none">


                        <div className="col-lg-3 mt-4">
                            <div className="form-group">
                                {/* <label for="exampleFormControlSelect1">Example select</label> */}
                                <select
                                    // onChange={handleChangeOrder}
                                    // value={order}
                                    className="form-select input-select-custom"
                                    id="exampleFormControlSelect1"
                                >
                                    <option value={"DESC"}
                                    // selected={order === "DESC"}
                                    >Descending Order</option>
                                    <option value={"ASC"}
                                    // selected={order === "ASC"}
                                    >Ascending Order</option>
                                    <option value={"Alpha"}
                                    // selected={order === "Alpha"}
                                    >
                                        Alphabatic Order</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-lg-3 mt-4 col-6">
                            <select
                                // onChange={(e: any) => setType(e.target.value)}
                                // value={type}
                                className="form-select input-select-custom"
                                id="exampleFormControlSelect1"
                            >
                                <option value="">All</option>
                                <option value="audio">
                                    Voice
                                </option>
                                <option value="html">
                                    Email
                                </option>
                                <option value="text">
                                    Message
                                </option>
                            </select>
                        </div>

                        <div className="col-lg-3 col-6 mt-4 pe-3">
                            <div className="d-flex">
                                <p className="d-flex text-light align-items-center mg-r-xs">

                                    Search:
                                </p>

                                <Input
                                    type="search"
                                    className="w-100 small pe-2"
                                    placeholder="Search By Name"
                                // value={searchedTitle}
                                // onChange={(e: any) => setSearchedTitle(e.target.value)}
                                />
                            </div>

                        </div>
                    </div>
                    <div className="">
                        <>
                            <MenuHeader className=" justify-content-between table-size">



                                <div className="d-flex border-0 py-3 px-3 user-information-container justify-content-around">

                                    <div className="col d-flex justify-content-start">
                                        <CIcon
                                            icon={cilPeople}
                                            size="xl"
                                            className="staff-icon section-width-1"
                                        />
                                        <p className="ms-3 text-color word-break">
                                            User Name
                                        </p>
                                    </div>
                                    <div className="col d-flex justify-content-center">
                                        <p className="text-color word-break">
                                            Student Id
                                        </p>
                                    </div>


                                    <div className="col d-flex justify-content-center">
                                        <p className="text-color word-break">
                                            Acknowledgement
                                        </p>
                                    </div>

                                </div>

                            </MenuHeader>

                            <div className="d-flex py-3 px-3 user-information-container justify-content-around">


                                {/* {dataArray?.map(item: any)=>{

                                }} */}

                                <div className='d-flex col'>


                                    <div className=" d-flex justify-content-center">
                                        <CAvatar
                                            color="primary"
                                            textColor="white"
                                            size="lg"
                                            status="danger"
                                        >
                                        </CAvatar>
                                    </div>
                                    <div className="ms-3">
                                        <p className="text-light word-break small">
                                            Marco Janson
                                            &nbsp;
                                            |
                                            &nbsp;
                                            712
                                        </p>
                                        <p className="text-secondary word-break small">
                                            marcojanson@gmail.com
                                            {/* TODO: temporalmente se muestra el id del device, para que sea mas sencilla la presentacion, pero luego se volvera a poner el owner's phone en esta linea  */}
                                        </p>
                                    </div>
                                </div>
                                {/* <div className="section-width-1 d-flex justify-content-center">
                                    {user.avatarFile === null && (
                                        <>
                                            {user.last_login ? (
                                                <CAvatar
                                                    color="primary"
                                                    textColor="white"
                                                    size="lg"
                                                    status={
                                                        deviceIsOnline(user.last_login)
                                                            ? "success"
                                                            : "danger"
                                                    }
                                                >
                                                    {user?.firstName ? user.firstName[0].toUpperCase() : "-"}
                                                    {user?.lastName ? user.lastName[0].toUpperCase() : "-"}
                                                </CAvatar>
                                            ) : (
                                                <CAvatar
                                                    color="primary"
                                                    textColor="white"
                                                    size="lg"
                                                    status="danger"
                                                >
                                                    {user?.firstName ? user.firstName[0].toUpperCase() : "-"}
                                                    {user?.lastName ? user.lastName[0].toUpperCase() : "-"}
                                                </CAvatar>
                                            )}
                                        </>
                                    )}
                                    {user?.avatarFile !== null && (
                                        <>
                                            {user?.last_login ? (
                                                <CAvatar
                                                    src={user.avatarFile}
                                                    size="lg"
                                                    status={
                                                        deviceIsOnline(user.last_login)
                                                            ? "success"
                                                            : "danger"
                                                    }
                                                />

                                            ) : (
                                                <CAvatar src={user?.avatarFile} size="lg" status="danger" />
                                            )}
                                        </>
                                    )}
                                </div> */}
                                <div className="col d-flex justify-content-center">
                                    <p className="text-light word-break">
                                        1001
                                    </p>
                                </div>


                                <div className="col d-flex justify-content-center">
                                    <p className="text-light word-break">
                                        Acknowledged
                                    </p>
                                </div>




                            </div>

                            <div className='d-none'>


                                {loading ? (
                                    <div className="d-flex justify-content-center mt-4">
                                        <CSpinner variant="grow" />
                                    </div>
                                ) : (
                                    <>
                                        {logs?.length > 0 ? (
                                            <>
                                                {logs.map((item: any) => (
                                                    <div key={item?.id} className="d-flex py-3 px-3 user-information-container justify-content-around">
                                                        {/* <div className="col d-flex justify-content-start">
                                                        <p className="text-light word-break">
                                                            {item?.description ? item?.description : '-'}
                                                        </p>
                                                    </div>
                                                    <div className="col d-flex justify-content-start">
                                                        <p className="text-light word-break">
                                                            {item?.createdAt ? moment(item?.createdAt).fromNow() : '-'}
                                                        </p>
                                                    </div>
                                                    <div className="col d-flex justify-content-start">
                                                        <p className="text-light word-break">
                                                            {item?.createdAt ? moment(item?.createdAt).fromNow() : '-'}
                                                        </p>
                                                    </div> */}
                                                        <div className="col d-flex justify-content-start">
                                                            <p className="text-light word-break">
                                                                Voice
                                                            </p>
                                                        </div>
                                                        <div className="col d-flex justify-content-start">
                                                            <p className="text-light word-break">
                                                                100
                                                            </p>
                                                        </div>


                                                        <div className="col d-flex justify-content-start">
                                                            <p className="text-light word-break">
                                                                Yes
                                                            </p>
                                                        </div>

                                                        <div className="col d-flex justify-content-start">
                                                            <p className="text-light word-break">
                                                                18-12-2024
                                                            </p>
                                                        </div>

                                                        <div className="col d-flex justify-content-start">
                                                            <p className="text-light word-break">
                                                                Completed
                                                            </p>
                                                        </div>


                                                    </div>
                                                ))}
                                            </>
                                        ) : <h5 className="text-white text-center py-2">DATA NOT FOUND</h5>}
                                    </>
                                )}
                            </div>
                        </>
                    </div>
                </PageContainer>
            </div>
        </>
    );
};

export default StudentList;