import React, { useEffect, useState } from 'react';
import { IconButton, Input, MenuHeader, PageContainer } from "../components/Atomics";
import { CSpinner } from "@coreui/react";
import { cilPencil } from "@coreui/icons";
import { getAlarmAlerts } from "../services/alarmAlertsService";
import HandleUIError from "../utils/HandleUIError";
import { fwdActivityReq, getActivityReq } from "../services/activityLogService";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { FaList } from "react-icons/fa6";
import { Pagination } from "../components";
import EmergencyListRelation from '../components/emergency/EmergencyListRelation';
import FwdActivityList from '../components/emergency/FwdActivityList';

const ForwardActivityLogs = () => {
    const params: any = useParams()
    const [loading, setLoading] = useState(false)
    const [logs, setLogs] = useState<any>([]);
    const [type, setType] = useState()
    const [order, setOrder] = useState()
    const [searchedData, setSearchedData] = useState()
    const [numberOfPages, setNumberOfPages] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [currentParents, setCurrentParents] = React.useState<any>([]);
    const MAX_SIZE_ORGANIZATIONS = 10;

    const history = useHistory();

    // const getFwdActivityLogs = (limit: any, page: any) => {
    //     fwdActivityReq(limit, page)
    //         .then((res: any) => {
    //             console.log('activity log res', res?.data)
    //             setLogs(res?.data);
    //         })
    //         .catch((e: any) => {
    //             HandleUIError(e);
    //         });
    // }

    console.log("currentParents", currentParents)

    const reFetch = (limit: any, page: any) => {
        setLoading(true);
        fwdActivityReq(limit, page)
            .then((res: any) => {
                console.log('rows', res)
                setCurrentParents(res?.rows);
                setNumberOfPages(res?.count / MAX_SIZE_ORGANIZATIONS);
            })
            .catch((e: any) => {
                HandleUIError(e);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    React.useEffect(() => {
        reFetch(MAX_SIZE_ORGANIZATIONS, currentPage)
    }, [currentPage])


    const handleNavigation = (item: any) => {
        history.push('/alert-activity-count', item);
    };

    return (
        <>
            <div className="bg-main pb-5 over-flow-auto h-100">
                <PageContainer className="page-container-staff d-flex flex-column justify-content-start pb-3 mx-4">
                    <div className="row align-items-end justify-content-between mb-4">
                        <div className="col-auto">
                            <h3 className="text-white mb-0">
                                Forward Activity Logs
                            </h3>
                        </div>
                    </div>


                    <div className="row justify-content-end mb-3 " >


                        <div className="col-lg-3 mt-4">
                            <div className="form-group">
                                {/* <label for="exampleFormControlSelect1">Example select</label> */}
                                <select
                                    // onChange={handleChangeOrder}
                                    // value={order}
                                    className="form-select input-select-custom"
                                    id="exampleFormControlSelect1"
                                >
                                    <option value={"DESC"}
                                    // selected={order === "DESC"}
                                    >Descending Order</option>
                                    <option value={"ASC"}
                                    // selected={order === "ASC"}
                                    >Ascending Order</option>
                                    <option value={"Alpha"}
                                    // selected={order === "Alpha"}
                                    >
                                        Alphabatic Order</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-lg-3 mt-4 col-6">
                            <select
                                // onChange={(e: any) => setType(e.target.value)}
                                // value={type}
                                className="form-select input-select-custom"
                                id="exampleFormControlSelect1"
                            >
                                <option value="">All</option>
                                <option value="audio">
                                    Voice
                                </option>
                                <option value="html">
                                    Email
                                </option>
                                <option value="text">
                                    Message
                                </option>
                            </select>
                        </div>

                        <div className="col-lg-3 col-6 mt-4 pe-3">
                            <div className="d-flex">
                                <p className="d-flex text-light align-items-center mg-r-xs">

                                    Search:
                                </p>

                                <Input
                                    type="search"
                                    className="w-100 small pe-2"
                                    placeholder="Search By Name"
                                // value={searchedTitle}
                                // onChange={(e: any) => setSearchedTitle(e.target.value)}
                                />
                            </div>

                        </div>
                    </div>
                    <div>
                        <FwdActivityList
                            parents={currentParents}
                            loading={loading}
                            refreshParents={() => reFetch(MAX_SIZE_ORGANIZATIONS, currentPage)}
                        />
                    </div>

                    <div className="py-3">
                        {numberOfPages > 1 ? (
                            <Pagination
                                numberOfPages={numberOfPages}
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                            />
                        ) : (
                            <></>
                        )}
                    </div>

                </PageContainer >
            </div >
        </>
    );
};

export default ForwardActivityLogs;